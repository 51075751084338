<div class="modal-header">
  <h5 class="modal-title">
    {{ "TAG.EDIT" | translate }}</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="close()"></button>
</div>
<div class="modal-body">
  <form name="editForm" #editForm="ngForm" novalidate>
    <div class="row g-3">
      <div class="col-md-6">
        <div class="hstack gap-1 justify-content-end">
          <button type="button" class="btn btn-sm btn-outline-primary btn-icon waves-effect waves-light" (click)="addTag()"><i class="ri-add-line"></i></button>
          <button type="button" class="btn btn-sm btn-outline-danger btn-icon waves-effect waves-light" [disabled]="!selectedTag.id" (click)="deleteTag()"><i class="ri-delete-bin-5-line"></i></button>
        </div>
        <select class="form-select" size="8" aria-label="edit tags" #tag (change)="onTagSelected()">
          <option *ngFor="let tag of tags" [value]="tag.id" [selected]="tag.id === selectedTag.id">{{ tag.name }}</option>
        </select>
      </div>
      <div class="col-md-6 mt-4">
        <label for="name" class="form-label">{{ "TAG.NAME" | translate }}</label>
        <div class="input-group">
          <input type="text" name="name" class="form-control" [disabled]="!selectedTag.id" [(ngModel)]="selectedTag.name">
          <button class="btn btn-outline-success" type="button" id="rename" [disabled]="!selectedTag.id" (click)="renameTag()"><i class="ri-check-line"></i></button>
        </div>
      </div>
    </div>
    <div class="col-lg-12 mt-2">
      <div class="hstack gap-2 justify-content-end">
        <button type="button" class="btn btn-primary" (click)="close()">{{ "GLOBAL.CLOSE" | translate }}</button>
      </div>
    </div>
  </form>
</div>
