import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { GlobalComponent } from 'src/app/global-component';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private tempFileUrl = environment.adminUrl + 'files/temporary/';

  constructor(private http: HttpClient) { }

  upload(uploadURL: string, file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.put(uploadURL, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  uploadTempFile(file: File, url?: string): Observable<{id: string, progress: number, body?: any}> {
    return this.uploadFile(file, url ? url : this.tempFileUrl);
  }

  tempFilePreviewUrl(tempId: string) {
    return this.tempFileUrl + tempId + '/preview';
  }

  private uploadFile(file: File, uploadUrl: string): Observable<{id: string, progress: number, body?: any}>  {
    return new Observable( (observer) => {
      if (file.size >= GlobalComponent.MAX_PICTURE_SIZE) {
        observer.error( { error: -1 });
      } else {
        const tempId = this.generateTempId();
        this.upload(uploadUrl + tempId, file).pipe(finalize(() => {

        }))
        .subscribe( {
          next: (uploadEvent: any) => {
            if (uploadEvent.type === HttpEventType.UploadProgress) {
              const progress = Math.round(100 * uploadEvent.loaded / uploadEvent.total);
              observer.next( { id: tempId, progress: progress });
            } else if (uploadEvent instanceof HttpResponse) {
              const body = JSON.stringify(uploadEvent.body);
              observer.next( { id: tempId, progress: 100, body: JSON.parse(body)});
              observer.complete();
            }
          },
          error: (err: any) => {
            console.error(err);
            observer.error('');
          }
        });
      }
    });
  }

  private generateTempId() {
    return Math.random().toString(36).slice(2, 12);
  }

}