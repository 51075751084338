import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let curError: string | undefined;

      switch(err.status) {
        case 401: // Unauthorized
          curError = 'error.unauthorized';
          break;
        case 403: // Forbidden
          curError = err.headers.get("x-igreja24happ-error");
          break;
      }
      
      if (!curError) {
        curError = (err.error && err.error.message) ? err.error.message : err.statusText;
      }

      return throwError( () => new Error(curError));
    }));
  }
}
