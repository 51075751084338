import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MainService } from '@app/core/services/main.service';

@Directive({
  selector: '[hasAnyAuthority]'
})
export class HasAnyAuthorityDirective {

  constructor(private templateRef: TemplateRef<any>,
              private vcRef: ViewContainerRef,
              private mainService: MainService) { }

  @Input()
  set hasAnyAuthority(value: string|string[]|undefined) {
    let result = false;
    if (value) {
      const authorities = typeof value === 'string' ? [ <string> value ] : <string[]> value;
      result = this.mainService.hasAnyAuthority(authorities);
    }

    if (result) {
      this.vcRef.createEmbeddedView(this.templateRef);
    } else {
      this.vcRef.clear();
    }
  }
}
