import { Component, OnInit, inject } from '@angular/core';
import { DialogUtil } from '../utils/dialog-utils';
import { FileUploadService } from '@app/core/services/general/file-upload.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable } from 'rxjs';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html'
})
export class BaseFormComponent<EntityType> {
  
  protected activeModal = inject(NgbActiveModal);
  protected uploader = inject(FileUploadService);
  protected dialog = inject(DialogUtil);

  submitted = false;
  isSaving = false;
  loadingImage = false;
  tempId: string;
  imagePreview = 'assets/images/placeholders/document.png';

  constructor() { }

  close() {
    this.activeModal.dismiss();
  }

  submit() {
    this.submitted = true;
    this.isSaving = true;

    let obs: Observable<EntityType> = this.save();
    obs.subscribe({
      next: (res) => {
        this.isSaving = false;
        this.activeModal.close(res);
      },
      error: (err) => {
        this.isSaving = false;
        this.dialog.openErrorDialog(err, this.activeModal);
      }
    });
  }

  save(): Observable<EntityType> {
    return EMPTY;
  }

  selectImage(event: any): void {
    const file:File = event.target.files[0];
    if (file) {
      this.loadingImage = true;
      this.uploader.uploadTempFile(file).subscribe( {
        next: (res) => {
          this.tempId = res.id;
        },
        complete: () =>{
          this.imagePreview = this.uploader.tempFilePreviewUrl(this.tempId);
          this.loadingImage = false;
        },
        error: (error) => { 
          console.error("view error: " + error);
          this.loadingImage = false;
        }
      });
    }
  }
}
