import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { BehaviorSubject, EMPTY, Observable, concatMap, from, of } from 'rxjs';

import { User } from '../../models/user.model';
import { environment } from '@environments/environment';

const AUTH_API = environment.adminApiUrl;

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private _user!: User;
    private _token?: string;

    public currentUserSubject: BehaviorSubject<User>;

    constructor(private http: HttpClient,
                private socialAuthService: SocialAuthService) {
        this._user = JSON.parse(localStorage.getItem('currentUser')!);
        this.currentUserSubject = new BehaviorSubject<User>(this._user);
        // this.currentUser = this.currentUserSubject.asObservable();
    }

    public getToken() {
        if (this._token) return this._token;

        return localStorage.getItem('authenticationToken') || sessionStorage.getItem('authenticationToken');
    }

    public get currentUser(): User {
        return this._user;
    }

    public set currentUser(user: User) {
        this._user = user;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }

    public isAuthentiated() {
        return !!this.getToken() && !!this._user;
    }

    public get authorities() {
        return this._user?.authorities;
    }

    login(username: string, password: string, rememberMe: boolean) {
        const credentials = { username, password, rememberMe };
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(AUTH_API + 'authenticate', credentials, { headers: headers, observe: 'response' });
    }

    account(): Observable<User>  {
        return this.http.get<User>(AUTH_API + 'account');
    }

    socialLoginWithFacebook(fbData: fb.AuthResponse) {
        const url = AUTH_API + "authenticate/facebook";
        const data = {
            token: fbData.accessToken,
            expiresIn: fbData.expiresIn,
            rememberMe: true,
          };
        return this.http.post(url, data, { observe: 'response' });
    }

    socialLoginWithGoogle(googleUser: any) {
        const url = AUTH_API + "authenticate/google";
        const data = {
            token: googleUser.idToken,
            rememberMe: true
          };
        return this.http.post(url, data, { observe: 'response' });
    }

    loginWithFacebook() {
        // login with facebook and return observable with fb access token on success
        const fbLoginPromise = new Promise<fb.StatusResponse>(resolve => FB.login(resolve));
        return from(fbLoginPromise).pipe(
            concatMap(({ authResponse }) => authResponse ? of(authResponse) : EMPTY)
        );
    }

    logout() {
        localStorage.clear();

        this.facebookLogout();
        this.googleLogout();

        this.currentUserSubject.next(null!);
    }
    
    resetPassword(email: string): Observable<string> {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        return this.http.post<string>(AUTH_API + 'account/reset_password/init', email, { headers: headers, responseType: 'text' as 'json'});
    }

    changePassword(currentPassword: string, newPassword: string): Observable<void>  {
        const body = { newPassword, currentPassword };
        return this.http.post<void>(AUTH_API + 'account/change_password', body);
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, first_name: string, password: string) {        
        return this.http.post(AUTH_API + 'signup', {
            email,
            first_name,
            password,
          }, httpOptions);
    }

    storeAuthenticationToken(token: string, rememberMe: boolean) {
        this._token = token;

        // TODO: What is this toast used for?
        localStorage.setItem('toast', 'true');
        if (rememberMe) {
            localStorage.setItem('authenticationToken', token);
        } else {
            sessionStorage.setItem('authenticationToken', token);
        }
    }

    private facebookLogout() {
        FB.getLoginStatus(function(response) {
            if (response.status === 'connected') {
                FB.logout();
            }
        });
    }

    private googleLogout() {
        // Added catch here to avoid errors in console
        this.socialAuthService.signOut().catch(() => {});
    }
}

