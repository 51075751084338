import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { createRequestOption } from 'src/app/shared/utils/http-request-util';
import { User } from '../models/user.model';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {

  private resourceUrl = environment.adminApiUrl + 'users';
  private churchResourceUrl = environment.adminApiUrl + 'churches';

  constructor(private http: HttpClient) { }

  create(user: User): Observable<User> {
    return this.http.post(this.resourceUrl, user);
  }

  update(user: User, churchId?: number): Observable<User> {
    return this.http.put(churchId ? this.resourceUrl + '?churchId=' + churchId : this.resourceUrl, user);
  }

  find(login: string): Observable<User> {
    return this.http.get(`${this.resourceUrl}/${login}`);
  }

  query(req?: any): Observable<User[]> {
    const params = createRequestOption(req);
    return this.http.get<User[]>(this.resourceUrl, { params });
  }

  queryByChurch(churchId: number, req?: any): Observable<HttpResponse<User[]>> {
    const queryParams = createRequestOption(req);
    const url = this.churchResourceUrl + '/' + churchId + '/users';
    return this.http.get<User[]>(url, { params: queryParams, observe: 'response' });
  }

  delete(login: string): Observable<Response> {
    return this.http.delete<Response>(`${this.resourceUrl}/${login}`);
  }

  deleteProfilePicture(): Observable<Response> {
    return this.http.delete<Response>(this.resourceUrl + '/profilePicture');
  }

  authorities(): Observable<string[]> {
    return this.http.get<string[]>('api/users/authorities');
  }
}
