import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true
  },
  {
    id: 2,
    name: 'church',
    label: 'MENUITEMS.CHURCH.TEXT',
    icon: 'bx bx-church',
    subItems: [
      {
        id: 3,
        name: 'church-form',
        label: 'MENUITEMS.CHURCH.LIST.DATA',
        link: '/church/edit',
        parentId: 2
      },
      {
        id: 4,
        name: 'offers',
        label: 'MENUITEMS.CHURCH.LIST.OFFERS',
        link: '/church/offers',
        parentId: 2
      },
      {
        id: 5,
        name: 'team',
        label: 'MENUITEMS.CHURCH.LIST.TEAM',
        link: '/church/staff',
        parentId: 2
      },
      {
        id: 6,
        name: 'ministries',
        label: 'MENUITEMS.CHURCH.LIST.MINISTRIES',
        link: '/church/ministries',
        parentId: 2
      },
      {
        id: 7,
        name: 'events',
        label: 'MENUITEMS.CHURCH.LIST.EVENTS',
        link: '/church/events',
        parentId: 2,
      },
      {
        id: 8,
        name: 'school',
        label: 'MENUITEMS.CHURCH.LIST.SCHOOL',
        link: '/church/school',
        parentId: 2,
      },
      {
        id: 9,
        name: 'partners',
        label: 'MENUITEMS.CHURCH.LIST.PARTNERS',
        link: '/church/partners',
        badge: {
          variant: 'bg-success',
          text: 'MENUITEMS.CHURCH.BADGE',
        },
        parentId: 2,
      }
    ]
  },
  {
    id: 100,
    name: 'community',
    label: 'MENUITEMS.COMMUNITY.TEXT',
    icon: 'ri-group-line',
    subItems: [
      {
        id: 101,
        name: 'congregations',
        label: 'MENUITEMS.COMMUNITY.LIST.CONGREGATIONS',
        link: '/church/community/congregations',
        parentId: 100
      },
      {
        id: 102,
        name: 'members',
        label: 'MENUITEMS.COMMUNITY.LIST.MEMBERS',
        link: '/church/community/members',
        parentId: 100
      },
      {
        id: 103,
        name: 'family-groups',
        label: 'MENUITEMS.COMMUNITY.LIST.FAMILYGROUPS',
        link: '/church/community/family-groups',
        parentId: 100
      },
      {
        id: 104,
        name: 'pray',
        label: 'MENUITEMS.COMMUNITY.LIST.PRAY',
        link: '/church/community/prays',
      },
      {
        id: 105,
        name: 'suggestions',
        label: 'MENUITEMS.COMMUNITY.LIST.SUGGESTIONS',
        link: '/church/community/suggestions',
      }
    ]
  },
  {
    id: 200,
    name: 'content',
    label: 'MENUITEMS.CONTENT.TEXT',
    icon: 'ri-pages-line',
    subItems: [
      // {
      //   id: 201,
      //   name: 'messages',
      //   label: 'MENUITEMS.CONTENT.LIST.MESSAGES',
      //   link: '/church/content/messages',
      //   parentId: 200
      // },
      {
        id: 202,
        name: 'library',
        label: 'MENUITEMS.CONTENT.LIST.LIBRARY',
        link: '/church/content/library',
        parentId: 200
      },
      {
        id: 203,
        name: 'pictures',
        label: 'MENUITEMS.CONTENT.LIST.PICTURES',
        link: '/church/content/gallery',
        parentId: 200
      },
      {
        id: 204,
        name: 'blog',
        label: 'MENUITEMS.CONTENT.LIST.BLOG',
        parentId: 200,
        subItems: [
          {
            id: 205,
            name: 'devotionals',
            label: 'MENUITEMS.CONTENT.LIST.DEVOTIONALS',
            link: '/church/content/blog/devotionals',
            parentId: 204
          },
          {
            id: 206,
            label: 'MENUITEMS.CONTENT.LIST.ARTICLES',
            link: '/church/content/blog/articles',
            parentId: 204
          },
          {
            id: 207,
            label: 'MENUITEMS.CONTENT.LIST.NEWS',
            link: '/church/content/blog/news',
            parentId: 204
          },
        ]
      },
      {
        id: 208,
        name: 'dailyverses',
        label: 'MENUITEMS.CONTENT.LIST.DAILYVERSES',
        link: '/church/content/verses',
        parentId: 200
      },
    ]
  },

  {
    id: 300,
    label: 'MENUITEMS.FINANCIAL.TEXT',
    icon: 'ri-money-dollar-circle-line',
    subItems: [
      {
        id: 301,
        label: 'MENUITEMS.FINANCIAL.LIST.BANKACCOUNTS',
        link: '/church/financial/bank-accounts',
        parentId: 300
      },
      {
        id: 302,
        label: 'MENUITEMS.FINANCIAL.LIST.PROVIDERS',
        link: '/church/financial/providers',
        parentId: 300
      },
      {
        id: 303,
        label: 'MENUITEMS.FINANCIAL.LIST.CATEGORIES',
        link: '/church/financial/transaction-categories',
        parentId: 300
      },
      {
        id: 304,
        label: 'MENUITEMS.FINANCIAL.LIST.COSTCENTERS',
        link: '/church/financial/cost-centers',
        parentId: 300
      },
      {
        id: 305,
        label: 'MENUITEMS.FINANCIAL.LIST.TRANSACTIONS',
        link: '/church/financial/transactions',
        parentId: 300
      }
    ]
  },
  {
    id: 400,
    label: 'MENUITEMS.SETTINGS.TEXT',
    icon: 'ri-settings-3-line',
    subItems: [
      {
        id: 401,
        label: 'MENUITEMS.SETTINGS.LIST.PERMISSIONS',
        link: '/church/settings/permissions',
        parentId: 400
      },
      {
        id: 402,
        label: 'MENUITEMS.SETTINGS.LIST.BIBLE',
        link: '/church/settings/bible',
        parentId: 400
      },
      {
        id: 403,
        label: 'MENUITEMS.SETTINGS.LIST.SITE',
        link: '/church/settings/site',
        parentId: 400
      }
    ]
  }
];
