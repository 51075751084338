import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Tag } from 'src/app/core/models/tag.model';
import { TagService } from 'src/app/core/services/general/tag.service';
import { MainService } from 'src/app/core/services/main.service';
import { DialogUtil } from '../../utils/dialog-utils';

@Component({
  selector: 'app-tags-form',
  templateUrl: './tags-form.component.html'
})
export class TagsFormComponent {
  @ViewChild('tag') tagElement!: ElementRef;

  public entity: string;
  tags: Tag[];
  selectedTag: Tag = new Tag();

  constructor(private translate: TranslateService,
              public activeModal: NgbActiveModal,
              private mainService: MainService,
              private tagService: TagService,
              private dialog: DialogUtil) { }

  ngOnInit() {
    this.loadTags();
  }

  loadTags() {
    this.tagService.query(this.mainService.activeChurch.id!, { sort: ['name,asc'], 'entity': this.entity })
      .subscribe( {
        next: (tags) => {
          this.tags = tags;
          this.selectTag(this.selectedTag ? this.selectedTag.id! : 0);
        },
        error: (error) => console.error(error)
      })
  }

  close() {
    this.activeModal.dismiss();
  }

  deleteTag() {
    this.dialog.openDeleteDialog("TAG.REMOVE").then( res => {
      if (res.value) {
        this.tagService.delete([this.selectedTag.id!]).subscribe( {
          next: (church) => this.loadTags(),
          error: (e) => {
            console.error(e); // FIXME: Show an error feedback to user
          }
        });
      }
    });
  }

  addTag() {
    const newTagName = this.translate.instant('TAG.NEW_NAME');
    const newTag = new Tag(undefined, this.entity, newTagName, this.tags.length);
    this.tagService.create(this.mainService.activeChurch.id!, newTag).subscribe( {
      next: (tag) => {
        this.loadTags();
      },
      error: (error) => console.error(error)
    });
  }

  renameTag() {
    if(this.selectedTag) {
      this.tagService.update(this.mainService.activeChurch.id!, this.selectedTag).subscribe( {
        next: (tag) => { this.loadTags();},
        error: (error) => console.error(error)
      })
    }
  }

  selectTag(id: number) {
    if (this.tags && this.tags.length > 0) {
      for(let tag of this.tags) {
        if (tag.id == id) {
          this.selectedTag = { ...tag };
          return;
        }
      }
      // If here, no tag was found, so we select the first one
      this.selectedTag = { ...this.tags[0] };
    } else {
      this.selectedTag = new Tag();
    }
  }

  onTagSelected() {
    const selectedId: number = this.tagElement.nativeElement.value;
    this.selectTag(selectedId);
  }
}
