import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbNavModule, NgbAccordionModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ScrollspyDirective } from './scrollspy.directive';
import { FormsModule } from '@angular/forms';
import { TagsFormComponent } from './tags/tags-form/tags-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from './spinner/spinner.component';
import { BaseFormComponent } from './base-form/base-form.component';
import { HasAnyAuthorityDirective } from './has-any-authority.directive';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    ScrollspyDirective,
    TagsFormComponent,
    SpinnerComponent,
    BaseFormComponent,
    HasAnyAuthorityDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgbNavModule,
    NgbAccordionModule,
    NgbDropdownModule
  ],
  exports: [
    FormsModule,
    TranslateModule,
    BaseFormComponent,
    BreadcrumbsComponent,
    TagsFormComponent,
    SpinnerComponent,
    ScrollspyDirective,
    HasAnyAuthorityDirective,
    DatePipe
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule { }
