import { Component, OnInit } from '@angular/core';

import flatpickr from 'flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Igreja 24h';

  ngOnInit(): void {
    flatpickr.localize(Portuguese);
  }
}
