import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EventService } from '@app/core/services/event.service';
import { AuthenticationService } from '@app/core/services/auth/auth.service';
import { LanguageService } from '@app/core/services/general/language.service';
import { ChangePasswordComponent } from '@app/pages/settings/change-password/change-password.component';
import { UserProfileComponent } from '@app/pages/settings/user-profile/user-profile.component';
import { User } from '@app/core/models/user.model';
import { MainService } from '@app/core/services/main.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  element: any;
  mode: string | undefined;
  @Output() mobileMenuButtonClicked = new EventEmitter();

  flagvalue: any;
  valueset: any;
  countryName: any;
  cookieValue: any;
  user: User;
  userAuthority = '';

  constructor(private eventService: EventService,
              public languageService: LanguageService,
              public _cookiesService: CookieService,
              public translate: TranslateService,
              public mainService: MainService,
              private authService: AuthenticationService,
              private modalService: NgbModal,
              private router: Router) { }

  ngOnInit(): void {
    this.authService.currentUserSubject.subscribe( user => {
      this.user = user;
      this.userAuthority = this.formatUserAuthority();
    });
    this.user = this.authService.currentUser;

    this.element = document.documentElement;

    // Cookies wise Language set
    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/br.svg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
  * Topbar Light-Dark Mode Change
  */
  changeMode(mode: string) {
    this.mode = mode;
    this.eventService.broadcast('changeMode', mode);

    switch (mode) {
      case 'light':
        document.body.setAttribute('data-layout-mode', "light");
        document.body.setAttribute('data-sidebar', "light");
        break;
      case 'dark':
        document.body.setAttribute('data-layout-mode', "dark");
        document.body.setAttribute('data-sidebar', "dark");
        break;
      default:
        document.body.setAttribute('data-layout-mode', "light");
        break;
    }
  }

  listLang = [
    { text: 'Português', flag: 'assets/images/flags/br.svg', lang: 'pt-br' },
    { text: 'English', flag: 'assets/images/flags/us.svg', lang: 'en' },
    { text: 'Española', flag: 'assets/images/flags/spain.svg', lang: 'es' }
  ];

  /***
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }

  changePassword() {
    return this.modalService.open(ChangePasswordComponent, { size: 'md', centered: true, backdrop: 'static', keyboard: false });
  }

  userProfile() {
    const modalRef = this.modalService.open(UserProfileComponent, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.user = { ...this.user };

    modalRef.result.then( (user) => {
      this.user = user;
      this.authService.currentUser = user;
    })
    .catch( () => { }); // We do nothing as this happens due to dialog cancel button (dismiss)
  }

  windowScroll() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      (document.getElementById('back-to-top') as HTMLElement).style.display = "block";
    } else {
      (document.getElementById('back-to-top') as HTMLElement).style.display = "none";
    }
  }

  private formatUserAuthority(): string {
    // FIXME: Finish this function!!
    if (this.user && this.user.authorities) {
      this.user.authorities.forEach( authority => {
        // console.log('user authority: ' + authority);
      })
    }

    return 'Staff';
  }

}
