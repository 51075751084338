<div id="layout-wrapper">
    <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>
    <app-horizontal-topbar *hasAnyAuthority="['ROLE_ADMIN', 'ROLE_MASTER']"></app-horizontal-topbar>
    <div class="main-content">
        <div class="page-content">
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
  </div>
  <!-- <app-rightsidebar  (settingsButtonClicked)="onSettingsButtonClicked()"></app-rightsidebar> -->
