import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { createRequestOption } from 'src/app/shared/utils/http-request-util';
import { Tag } from '../../models/tag.model';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class TagService {
  private resourceUrl = environment.adminApiUrl + 'tags';
  private churchResourceUrl = environment.adminApiUrl + 'churches';

  constructor(private http: HttpClient) { }

  create(churchId: number, tag: Tag): Observable<Tag> {
    const url = this.churchResourceUrl + '/' + churchId + '/tags';
    return this.http.post<Tag>(url, tag);
  }

  update(churchId: number, tag: Tag): Observable<Tag> {
    const url = this.churchResourceUrl + '/' + churchId + '/tags';
    return this.http.put<Tag>(url, tag);
  }

  find(tagId: number): Observable<Tag> {
    const url = `${this.resourceUrl}/${tagId}`;
    return this.http.get<Tag>(url);
  }

  query(churchId: number, req?: any): Observable<Tag[]> {
    const queryParams = createRequestOption(req);
    const url = this.churchResourceUrl + '/' + churchId + '/tags';
    return this.http.get<Tag[]>(url, {params: queryParams});
  }

  delete(ids: number[]): Observable<Response> {
    const url = `${this.resourceUrl}/${ids.join()}`;
    return this.http.delete<Response>(url);
  }

}