<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
               {{year}} © Igreja24h.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    <!-- Fixme: Translate -->
                    Desenvolvido por Igreja24h
                </div>
            </div>
        </div>
    </div>
</footer>