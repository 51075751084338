import { Injectable } from '@angular/core';
import { Church } from '../models/church/church.model';
import { UserPermission } from '../models/auth/permission.model';
import { AuthenticationService } from './auth/auth.service';
import { UserPermissionService } from './auth/user-permission.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class MainService {
    private _activeChurch: Church;
    private _permissions: UserPermission[] = [];

    constructor(private authService: AuthenticationService,
                private permissionService: UserPermissionService,
                private router: Router) {
        if (localStorage.getItem('activeChurch')) {
            this._activeChurch = JSON.parse(localStorage.getItem('activeChurch')!);
        }

        if (localStorage.getItem('permissions')) {
            this._permissions = JSON.parse(localStorage.getItem('permissions')!);
        }
    }

    public get activeChurch(): Church {
        return this._activeChurch;
    }

    public set activeChurch(church: Church | null) {
        if (church) {
            this._activeChurch = church;
            localStorage.setItem('activeChurch', JSON.stringify(church));
        } else {
            this._activeChurch = undefined!;
            localStorage.removeItem('activeChurch');
        }
        // FIXME
        // this.updateUserChurchAuthorities();
    }

    public get permissions(): UserPermission[] {
        return this._permissions;
    }

    public set permissions(permissions: UserPermission[]) {
        this._permissions = permissions;
        localStorage.setItem('permissions', JSON.stringify(permissions));
    }

    public get staffPermissions(): UserPermission[] {
        return this.permissions.filter(permission => permission.type === UserPermission.PERMISSION_STAFF);
    }

    public isAdmin() {
        return this.hasAuthority("ROLE_ADMIN");
    }

    public isMaster() {
        return this.hasAuthority("ROLE_MASTER");
    }

    public hasAuthority(authority: string): boolean {
        const userAuthorities = this.authService.authorities;
        if (!this.authService.isAuthentiated() || !userAuthorities) {
            return false;
        }
        return userAuthorities.indexOf(authority) !== -1;
    }

    public hasAnyAuthority(authorities: string[]): boolean {
        for (let authority of authorities) {
            if (this.hasAuthority(authority)) {
                return true;
            }
        }

        return false;
    }

    public loginSuccess(token: string, returnUrl?: string) {
        this.authService.storeAuthenticationToken(token, true);
        this.authService.account().subscribe({
            next: (userAccount) => {
                this.authService.currentUser = userAccount;
                this.permissionService.queryByUser(userAccount.id).subscribe( {
                next: (permissions) => {
                    this.permissions = permissions;
                    if(this.staffPermissions.length === 1) {
                        console.log("Staff has only one church. Open it!");
                        this.activeChurch = this.staffPermissions[0].church!;
                        if (returnUrl) {
                            this.router.navigate([returnUrl]);
                        } else {
                            this.router.navigate(['/church', 'edit']);
                        }
                    } else {
                        console.log("Staff has many churches. List them!");
                        this.router.navigate(['/']);
                    }
                }
                });
            },
            error: (err) => {
                console.log(err);
            }
        });
    }

}
