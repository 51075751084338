<header id="page-topbar" data-scroll-header (window:scroll)="windowScroll()">
    <div class="layout-width">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box horizontal-logo">
                    <a routerLink="" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-dark.png" alt="" height="30">
                        </span>
                    </a>

                    <a routerLink="" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="30">
                        </span>
                    </a>
                </div>

                <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                    id="topnav-hamburger-icon" (click)="toggleMobileMenu($event)">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>
            </div>

            <div class="d-flex align-items-center">

                <!-- Languages -->
                <div class="dropdown ms-1 topbar-head-dropdown header-item" ngbDropdown>
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="20"
                            class="rounded">
                        <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="20"
                            class="rounded">
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>

                        <!-- item-->
                        <a href="javascript:void(0);" class="dropdown-item notify-item language py-2" data-lang="eng"
                            *ngFor="let item of listLang" (click)="setLanguage(item.text, item.lang, item.flag)"
                            [ngClass]="{'active': cookieValue === item.lang}">
                            <img src="{{item.flag}}" alt="user-image" class="me-2 rounded" height="18"> <span
                                class="align-middle">{{item.text}}</span>
                        </a>
                    </div>
                </div>

                <!-- Links dropdown button: Site, Android App, IOS App -->
                <div *ngIf="mainService.activeChurch" class="dropdown topbar-head-dropdown ms-1 header-item" ngbDropdown>
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <i class='bx bx-category-alt fs-22'></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end" ngbDropdownMenu>
                        <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0 fw-semibold fs-15"> {{ 'TOPBAR.LINKS' | translate}} </h6>
                                </div>
                            </div>
                        </div>

                        <div class="p-2">
                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <i class="ri-links-line"></i>
                                        <span>Site</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/android.png">
                                        <span>Android</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/apple.png">
                                        <span>Iphone</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Profile -->
                <div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
                    <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <span class="d-flex align-items-center">
                            <img class="rounded-circle header-profile-user" src="assets/images/placeholders/user.jpg"
                                alt="Header Avatar">
                            <span class="text-start ms-xl-2">
                                <span
                                    class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ user ? user.firstName : ''}}
                                    {{ user ? user.lastName : ''}}</span>
                                <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{ userAuthority }}</span>
                            </span>
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <!-- item-->
                        <h6 class="dropdown-header">{{ "TOPBAR.WELCOME" | translate }}</h6>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="userProfile()"><i
                                class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">{{ "TOPBAR.PROFILE" | translate }}</span></a>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="changePassword()"><i
                                class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">{{ "TOPBAR.CHANGE_PASSWORD" | translate }}</span></a>
                        <!-- <a class="dropdown-item" routerLink="/pages/faqs"><i
                                class="mdi mdi-help-circle-outline text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">{{ "TOPBAR.HELP" | translate }}</span></a> -->
                        <a class="dropdown-item" routerLink="/public/privacy" target="_blank"><i
                                class="mdi mdi-file-document-outline text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">{{ "TOPBAR.PRIVACY" | translate }}</span></a>
                        <div class="dropdown-divider"></div>
                        <a id="topbar-logout" class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i
                                class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle" data-key="t-logout">{{ "TOPBAR.LOGOUT" | translate }}</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>