import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "@app/core/services/auth/auth.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import Swal, { SweetAlertIcon } from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class DialogUtil {

  constructor(private translate: TranslateService,
              private auth: AuthenticationService,
              private router: Router) { }

  public openDeleteDialog(text: string) {
    return Swal.fire({
      text: this.translate.instant(text),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#364574',
      cancelButtonColor: 'rgb(243, 78, 78)',
      confirmButtonText: this.translate.instant('GLOBAL.YES'),
      cancelButtonText: this.translate.instant('GLOBAL.NO')
    });
  }

  public openGenericErrorDialog(text?: string, icon?: SweetAlertIcon) {
    return Swal.fire({
      title: this.translate.instant('ERROR.TITLE'),
      text: this.translate.instant(text ? text : 'ERROR.GENERAL'),
      cancelButtonColor: '#364574',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: this.translate.instant('GLOBAL.CLOSE'),
      icon: icon ? icon : 'error'});
  }

  /**
   *  Attention in the returned promise. If the returned value is true,
   *  it means that the user choosed the action showed in the dialog.
   *  If it is false, the user just pressed back to return to the previous page.
   * 
   * @param i24hError the error received from backend
   * @param parentModel if the error dialog is called from another model like a form dialog,
   * this paramenter can be used to close the dialog depending on the error and user choice
  */
  public openErrorDialog(i24hError: Error, parentModal?: NgbActiveModal) {
    let msg = 'ERROR.GENERAL';
    let icon: SweetAlertIcon = 'error';

    console.error(i24hError);
    switch(i24hError.message) {
      case 'error.unauthorized':
        return this.openLoginNeededDialog(parentModal);
      case 'error.plan-limit':
        msg = 'ERROR.PLANLIMIT';
        icon = 'warning';
        break;
    }

    return this.openGenericErrorDialog(msg, icon);
  }

  /**
   * Opens a dialog to show to the user that the login is needed. If user presses button "Login"
   * the auth service is called to logout the user and the router is called to navigate to login page.
   *
   * @returns The promise with SweetAlertResult showing the user choice. True in res.value means login was choosed.
   */
  public openLoginNeededDialog(parentModal?: NgbActiveModal) {
    return Swal.fire({
      text: this.translate.instant('ERROR.UNAUTHORIZED'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#405189',
      confirmButtonText: this.translate.instant('GLOBAL.LOGIN'),
      cancelButtonText: this.translate.instant('GLOBAL.BACK')
    }).then( res => {
      if (res.value) {
        this.auth.logout();
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.router.url } });
        if (parentModal) {
          // TODO: This closes only the parent modal, but if there are more than one modal opened, it will not close the others
          parentModal.dismiss();
        }
      }
      return res;
    });;
  }
}
