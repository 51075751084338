import { Church } from "../church/church.model";
import { User } from "../user.model";

export class UserPermission {

  public static PERMISSION_ADMIN = 'ROLE_ADMIN';
  public static PERMISSION_STAFF = 'ROLE_STAFF';
  public static PERMISSION_SALES = 'ROLE_SALES';
  public static PERMISSION_CUSTOM = 'ROLE_CUSTOM';
  public static PERMISSION_MEMBER = 'ROLE_MEMBER';
  public static PERMISSION_USER = 'ROLE_USER';

  constructor(
      public id?: number,
      public type?: string,
      public user?: User,
      public church?: Church,
  ) {
  }
}
