<div class="modal-body">

  <div class="position-relative mx-n4 mt-n4">
    <div class="profile-wid-bg profile-setting-img" style="height: 70px"></div>
  </div>

  <div class="row">
    <div class="col-xxl-3">
        <div class="card mt-n5">
            <div class="card-body p-4">
                <div class="text-center">
                    <div class="profile-user position-relative d-inline-block mx-auto mb-4">
                        <img src="assets/images/placeholders/user.jpg"
                            class="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile-image">
                        <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                            <input id="profile-img-file-input" type="file" class="profile-img-file-input">
                            <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                                <span class="avatar-title rounded-circle bg-light text-body">
                                    <i class="ri-camera-fill"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                    <h5 class="fs-16 mb-1">{{ user.firstName }}</h5>
                    <p class="text-muted mb-0">{{ user.email }}</p>
                </div>
            </div>
        </div><!--end card-->
        <!-- <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center mb-5">
                    <div class="flex-grow-1">
                        <h5 class="card-title mb-0">{{ "PROFILE.COMPLETE_TITLE" | translate }}</h5>
                    </div>
                </div>
                <div class="progress animated-progess custom-progress progress-label">
                    <div class="progress-bar bg-danger" role="progressbar" style="width: 30%" aria-valuenow="30"
                        aria-valuemin="0" aria-valuemax="100">
                        <div class="label">30%</div>
                    </div>
                </div>
            </div>
        </div> -->

    </div><!--end col-->
    <div class="col-xxl-9">
        <div class="card mt-xxl-n5">
            <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="1"
                    class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li [ngbNavItem]="1" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-home"></i>
                            {{ "PROFILE.PERSONAL_DATA" | translate }}
                        </a>
                        <ng-template ngbNavContent>
                            <form action="javascript:void(0);">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="fullnameInput" class="form-label">{{ "PROFILE.FULLNAME" | translate }}</label>
                                            <input type="text" class="form-control" id="fullnameInput" name="fullnameInput"
                                                placeholder="{{ 'PROFILE.FULLNAME_PLACEHOLDER' | translate }}" [(ngModel)]="user.firstName">
                                        </div>
                                    </div><!--end col-->
                                    <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label for="nicknameInput" class="form-label">{{ "PROFILE.NICKNAME" | translate }}</label>
                                          <input type="text" class="form-control" id="nicknameInput" name="nicknameInput"
                                              placeholder="{{ 'PROFILE.NICKNAME_PLACEHOLDER' | translate }}" [(ngModel)]="user.nickname">
                                      </div>
                                    </div><!--end col-->
                                    <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label for="genderInput" class="form-label">{{ "GLOBAL.GENDER" | translate }}</label>
                                          <select id="genderInput" name="genderInput" class="form-control form-select" [(ngModel)]="user.gender">
                                            <option [ngValue]="'M'">{{"GLOBAL.MALE" | translate}}</option>
                                            <option [ngValue]="'F'">{{"GLOBAL.FEMALE" | translate}}</option>
                                        </select>
                                      </div>
                                    </div><!--end col-->
                                    <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label for="phoneInput" class="form-label">{{ "PROFILE.PHONE" | translate }}</label>
                                          <input type="text" class="form-control" id="phoneInput" name="phoneInput"
                                              placeholder="{{ 'PROFILE.PHONE_PLACEHOLDER' | translate }}" [(ngModel)]="user.phone">
                                      </div>
                                    </div><!--end col-->
                                    <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label for="mobileInput" class="form-label">{{ "PROFILE.MOBILE" | translate }}</label>
                                          <input type="text" class="form-control" id="mobileInput" name="mobileInput"
                                              placeholder="{{ 'PROFILE.MOBILE_PLACEHOLDER' | translate }}" [(ngModel)]="user.phone2">
                                      </div>
                                    </div><!--end col-->
                                    <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label for="birthdayInput" class="form-label">{{ "PROFILE.BIRTHDAY" | translate }}</label>
                                          <input id="birthdayInput" name="birthdayInput" class="form-control flatpickr-input" type="text" [(ngModel)]="user.birthday"
                                            mwlFlatpickr [altInput]="true" [convertModelValue]="true" altFormat="F j, Y" dateFormat="Y-m-d"
                                            placeholder="{{ 'PROFILE.DATE_PLACEHOLDER' | translate }}">
                                      </div>
                                    </div><!--end col-->
                                    <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label for="weddingInput" class="form-label">{{ "PROFILE.WEDDING" | translate }}</label>
                                          <input id="weddingInput" name="weddingInput" class="form-control flatpickr-input" type="text" [(ngModel)]="user.weddingDate"
                                            mwlFlatpickr [altInput]="true" [convertModelValue]="true" altFormat="F j, Y" dateFormat="Y-m-d"
                                            placeholder="{{ 'PROFILE.DATE_PLACEHOLDER' | translate }}">
                                      </div>
                                    </div><!--end col-->
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="jobInput" class="form-label">{{ "PROFILE.JOB" | translate }}</label>
                                            <input type="text" class="form-control" id="jobInput" name="jobInput" [(ngModel)]="user.job">
                                        </div>
                                    </div><!--end col-->

                                    <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label for="roleInput" class="form-label">{{ "PROFILE.ROLE" | translate }}</label>
                                          <input type="text" class="form-control" id="roleInput" name="roleInput" [(ngModel)]="user.role">
                                      </div>
                                    </div><!--end col-->

                                </div><!--end row-->
                            </form>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-user"></i>
                            {{ "GLOBAL.SOCIALNETWORKS" | translate }}
                        </a>
                        <ng-template ngbNavContent>
                            <form action="javascript:void(0);">
                              <div class="col-md-12">
                                <div class="d-flex mb-3">
                                  <div class="avatar-xs d-block flex-shrink-0 me-3">
                                    <span class="avatar-title rounded-circle fs-16 bg-primary text-light">
                                        <i class="ri-facebook-fill"></i>
                                    </span>
                                  </div>
                                  <input name="facebookInput" type="text" class="form-control" id="facebookInput" [(ngModel)]="user.facebook" placeholder="Facebook">
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="d-flex mb-3">
                                  <div class="avatar-xs d-block flex-shrink-0 me-3">
                                    <span class="avatar-title rounded-circle fs-16 bg-primary text-light">
                                        <i class="ri-instagram-fill"></i>
                                    </span>
                                  </div>
                                  <input name="instagramInput" type="text" class="form-control" id="instagramInput" [(ngModel)]="user.instagram" placeholder="Instagram">
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="d-flex mb-3">
                                  <div class="avatar-xs d-block flex-shrink-0 me-3">
                                    <span class="avatar-title rounded-circle fs-16 bg-primary text-light">
                                        <i class="ri-youtube-fill"></i>
                                    </span>
                                  </div>
                                  <input name="youtubeInput" type="text" class="form-control" id="youtubeInput" [(ngModel)]="user.youtube" placeholder="Youtube">
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="d-flex mb-3">
                                  <div class="avatar-xs d-block flex-shrink-0 me-3">
                                    <span class="avatar-title rounded-circle fs-16 bg-primary text-light">
                                        <i class="ri-twitter-fill"></i>
                                    </span>
                                  </div>
                                  <input name="twitterInput" type="text" class="form-control" id="twitterInput" placeholder="Twitter">
                                </div>
                              </div>
                            </form>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i>
                            {{ "PROFILE.ADDRESS" | translate }}
                        </a>
                        <ng-template ngbNavContent>
                            <form>
                              <div class="row g-3">
                                <div class="col-md-12">
                                  <label for="street" class="form-label">{{"PROFILE.ADDRESS" | translate}}</label>
                                  <input name="street" type="text" class="form-control" id="streetInput" [(ngModel)]="user.address">
                                </div>
                                <div class="col-md-6">
                                  <label for="neighborhood" class="form-label">{{"GLOBAL.NEIGHBORHOOD" | translate}}</label>
                                  <input name="neighborhood" type="text" class="form-control" id="neighborhoodInput" [(ngModel)]="user.neighborhood">
                                </div>
                                <div class="col-md-6">
                                  <label for="city" class="form-label">{{"GLOBAL.CITY" | translate}}</label>
                                  <input name="city" type="text" class="form-control" id="cityInput" [(ngModel)]="user.city">
                                </div>
                                <div class="col-md-6">
                                  <label for="state" class="form-label">{{"GLOBAL.STATE" | translate}}</label>
                                  <input name="state" type="text" class="form-control" id="stateInput" [(ngModel)]="user.state">
                                </div>
                                <div class="col-md-6">
                                  <label for="country" class="form-label">{{"GLOBAL.COUNTRY" | translate}}</label>
                                  <input name="country" type="text" class="form-control" id="countryInput" [(ngModel)]="user.country">
                                </div>
                                <div class="col-md-6">
                                  <label for="zipcode" class="form-label">{{"GLOBAL.ZIPCODE" | translate}}</label>
                                  <input name="zipcode" type="text" class="form-control" id="zipcodeInput" [(ngModel)]="user.zipcode" maxlength="10">
                                </div>
                              </div>
                            </form>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i>
                            {{"PROFILE.BIO" | translate}}
                        </a>
                        <ng-template ngbNavContent>
                          <div class="form">
                            <div class="row">
                              <div class="col-12">
                                <div class="mb-3 pb-2">
                                    <textarea class="form-control" id="bioInput" name="bioInput" [(ngModel)]="user.obs"
                                        placeholder=" {{'PROFILE.BIO_PLACEHOLDER' | translate}}" rows="8"></textarea>
                                </div>
                            </div><!--end col-->
                            </div>
                          </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body p-4">
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>

                <div class="col-lg-12">
                  <div class="hstack gap-2 justify-content-end">
                    <button type="button" class="btn btn-soft-success" (click)="close()">{{ "GLOBAL.CANCEL" | translate }}</button>
                    <button type="submit" class="btn btn-primary" (click)="save()">{{ "GLOBAL.SAVE" | translate }}</button>
                  </div>
                </div><!--end col-->
            </div>
        </div>
    </div><!--end col-->
  </div><!--end row-->
</div>
