import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserPermission } from '../../models/auth/permission.model';
import { createRequestOption } from 'src/app/shared/utils/http-request-util';
import { Church } from '../../models/church/church.model';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UserPermissionService {

  private resourceUrl = environment.adminApiUrl + 'permissions';
  private churchResourceUrl = environment.adminApiUrl + 'churches';

  constructor(private http: HttpClient) { }

  create(permission: UserPermission): Observable<UserPermission> {
    return this.http.post<UserPermission>(this.resourceUrl, permission);
  }

  update(permission: UserPermission): Observable<UserPermission> {
    return this.http.put<UserPermission>(this.resourceUrl, permission);
  }

  find(permissionId: number): Observable<Church> {
    const url = `${this.resourceUrl}/${permissionId}`;
    return this.http.get<UserPermission>(url);
  }

  queryByUser(userId: number, req?: any): Observable<UserPermission[]> {
    const params = createRequestOption(req);
    return this.http.get<UserPermission[]>(this.resourceUrl + '/user/' + userId, { params });
  }

  queryByChurch(churchId?: number, req?: any): Observable<UserPermission[]> {
    const params = createRequestOption(req);
    return this.http.get<UserPermission[]>(this.churchResourceUrl + '/' + churchId + '/permissions', { params });
  }

  query(req?: any, userId?: number, churchId?: number): Observable<HttpResponse<UserPermission[]>> {
    const params = createRequestOption(req);

    if (userId) {
      return this.http.get<UserPermission[]>(this.resourceUrl + '/user/' + userId, { params, observe: 'response' });
    }

    if (churchId) {
      return this.http.get<UserPermission[]>(this.churchResourceUrl + '/' + churchId + '/permissions', { params, observe: 'response' });
    }

    return this.http.get<UserPermission[]>(this.resourceUrl, { params, observe: 'response' });
  }

  delete(ids: number[]): Observable<Response> {
    const url = `${this.resourceUrl}/${ids.join()}`;
    return this.http.delete<Response>(url);
  }
}
