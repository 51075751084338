import { HttpParams } from '@angular/common/http';

export const createRequestOption = (req?: any): HttpParams => {
    let params: HttpParams = new HttpParams();
    if (req) {
        if (req.page != null) {
            params = params.set('page', req.page);
        }
        if (req.size) {
            params = params.set('size', req.size);
        }
        if (req.sort) {
            (req.sort as []).forEach( (value) => {
                params = params.append('sort', value);
            });
        }
        if (req.type) {
            params = params.set('type', req.type);
        }
        if (req.role) {
            params = params.set('role', req.role);
        }
        if (req.name) {
            params = params.set('name', req.name);
        }
        if (req.search) {
            params = params.set('search', req.search);
        }
        if (req.birthdayMonth) {
            params = params.set('birthdayMonth', req.birthdayMonth);
        }
        if (req.entity) {
            params = params.set('entity', req.entity);
        }
        if (req.all) {
            params = params.set('all', req.all);
        }
        if (req.sellerId) {
            params = params.set('sellerId', req.sellerId);
        }
        if (req.userId) {
            params = params.set('userId', req.userId);
        }
        if (req.country) {
            params = params.set('country', req.country);
        }
        if (req.tagId) {
            params = params.set('tagId', req.tagId);
        }
        // As status can be false, we check undefined and null
        if (req.status !== undefined && req.status !== null) {
            params = params.set('status', req.status);
        }
    }
    return params;
};
