import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { User } from '@app/core/models/user.model';
import { MainService } from '@app/core/services/main.service';
import { UserService } from '@app/core/services/user.service';
import { DialogUtil } from '@app/shared/utils/dialog-utils';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html'
})
export class UserProfileComponent implements OnInit {
  public user: User;

  constructor(private activeModal: NgbActiveModal,
              private mainService: MainService,
              private userService: UserService,
              private dialog: DialogUtil) { }

  ngOnInit(): void { }

  close() {
    this.activeModal.dismiss();
  }

  save() {
    this.userService.update(this.user).subscribe({
      next: (user) => {
        this.activeModal.close(user);
      },
      error: (err) => {
        console.error(err);
        this.dialog.openGenericErrorDialog();
      }
    })
  }

}
