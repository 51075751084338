const I24h_BACKEND = "https://dev.igreja24h.com.br/";
// const I24h_BACKEND = "https://localhost:8080/";

export const environment = {
  production: false,
  adminUrl: I24h_BACKEND,
  adminApiUrl : I24h_BACKEND + 'api/',
  facebookAppId: '771200983311588',
  googleAppId: '35917281386-3r4h1msgqn45pte433lds14v0bd9f755.apps.googleusercontent.com',
  googleAnalyticsId: 'UA-179545428-1' // Property: newdev.igreja24h.com.br
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
