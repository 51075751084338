import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'TOPMENUITEMS.MENU.TEXT',
    isTitle: true
  },
  {
    id: 1,
    label: 'MENUITEMS.CHURCH.TEXT',
    icon: 'ri-home-4-line',
    link: '/',
  },
  {
    id: 2,
    label: 'TOPMENUITEMS.SALES.TEXT',
    icon: 'ri-shopping-bag-line',
    subItems: [
      {
        id: 3,
        label: 'TOPMENUITEMS.SALES.LIST.PLANS',
        link: '/sales/subscription-plans',
        parentId: 2
      },
      {
        id: 4,
        label: 'TOPMENUITEMS.SALES.LIST.SUBSCRIPTIONS',
        link: '/sales/subscriptions',
        parentId: 2
      },
    ]
  },
  {
    id: 5,
    label: 'TOPMENUITEMS.ADMIN.TEXT',
    icon: 'ri-settings-4-line',
    subItems: [
      {
        id: 6,
        label: 'TOPMENUITEMS.ADMIN.LIST.USERS',
        link: '/admin/users',
        parentId: 5
      },
      {
        id: 7,
        label: 'TOPMENUITEMS.ADMIN.LIST.PERMISSIONS',
        link: '/admin/permissions',
        parentId: 5
      },
    ]
  },
];
