<div class="modal-header">
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body" style="min-height: 60vh; overflow-y: auto;">

  <div class="text-center mt-2">
      <h5 class="text-primary">{{ "LOGIN.CHANGE_PASSWORD_TITLE" | translate }}</h5>
      <p class="text-muted">{{ "LOGIN.CHANGE_PASSWORD_INFO" | translate }}</p>
  </div>

  <div class="p-2">
      <form name="editForm" #editForm="ngForm" [formGroup]="passResetForm" (ngSubmit)="save()">
            <div class="mb-3">
              <label class="form-label" for="current-input">{{ "LOGIN.CURRENT_PASSWORD" | translate }}</label>
              <div class="position-relative">
                  <input [type]="currentPasswordField ? 'text' : 'password'" class="form-control pe-5 password-input" placeholder="{{ 'LOGIN.CURRENT_PASSWORD_PLACEHOLDER' | translate }}" id="current-input" aria-describedby="currentPasswordInput" required formControlName="currentPassword"
                  [ngClass]="{ 'is-invalid': submitted && f['currentPassword'].errors }">
                  <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                      id="password-addon"><i class="ri-eye-fill align-middle" [ngClass]="{'mdi-eye-off-outline': !currentPasswordField, 'mdi-eye-outline': currentPasswordField}" (click)="toggleCurrentPasswordField()"></i></button>
                  <div *ngIf="submitted && f['currentPassword'].errors" class="invalid-feedback">
                      <span *ngIf="f['currentPassword'].errors['required']">Current password is required</span>
                    </div>
              </div>
          </div>
          <div class="mb-3">
              <label class="form-label" for="password-input">{{ "LOGIN.NEW_PASSWORD" | translate }}</label>
              <div class="position-relative auth-pass-inputgroup">
                  <input [type]="passwordField ? 'text' : 'password'" class="form-control pe-5 password-input" placeholder="{{ 'LOGIN.NEW_PASSWORD_PLACEHOLDER' | translate }}" id="password-input" aria-describedby="passwordInput" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required formControlName="password"
                  [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                  <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                      id="password-addon"><i class="ri-eye-fill align-middle" [ngClass]="{'mdi-eye-off-outline': !passwordField, 'mdi-eye-outline': passwordField}" (click)="togglePasswordField()"></i></button>
                  <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                      <span *ngIf="f['password'].errors['required']">{{ "LOGIN.PASSWORD_REQUIRED" | translate }}</span>
                    </div>
              </div>
              <div id="passwordInput" class="form-text">{{ "LOGIN.PASSWORD_MIN_SIZE" | translate }}</div>
          </div>

          <div class="mb-3">
              <label class="form-label" for="confirm-password-input">{{ "LOGIN.CONFIRM_PASSWORD" | translate }}</label>
              <div class="position-relative auth-pass-inputgroup mb-3">
                  <input [type]="confirmField ? 'text' : 'password'" class="form-control pe-5 password-input" placeholder="{{ 'LOGIN.CONFIRM_PASSWORD_PLACEHOLDER' | translate }}" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" id="confirm-password-input" required formControlName="cpassword"
                  [ngClass]="{ 'is-invalid': submitted && f['cpassword'].errors }">
                  <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="confirm-password-input"><i class="ri-eye-fill align-middle" [ngClass]="{'mdi-eye-off-outline': !passwordField, 'mdi-eye-outline': passwordField
                  }" (click)="toggleConfirmField()"></i></button>
                  <div *ngIf="submitted && f['cpassword'].errors" class="invalid-feedback">
                      <span *ngIf="f['cpassword'].errors['required']">{{ "LOGIN.CONFIRM_PASSWORD_REQUIRED" | translate }}</span>
                    </div>
              </div>
          </div>

          <div id="password-contain" class="p-3 bg-light mb-2 rounded">
              <h5 class="fs-13">{{ "LOGIN.PASSWORD_MUST_HAVE" | translate }}</h5>
              <p id="pass-length" class="invalid fs-12 mb-2" [innerHTML]="'LOGIN.PASSWORD_MUST_MIN' | translate"></p>
              <p id="pass-lower" class="invalid fs-12 mb-2">{{ "LOGIN.PASSWORD_MUST_LOWERCASE" | translate }}</p>
              <p id="pass-upper" class="invalid fs-12 mb-2">{{ "LOGIN.PASSWORD_MUST_UPPERCASE" | translate }}</p>
              <p id="pass-number" class="invalid fs-12 mb-0">{{ "LOGIN.PASSWORD_MUST_NUMBER" | translate }}</p>
          </div>

          <div class="mt-4">
            <span *ngIf="isSaving" class="spinner-border flex-shrink-0 me-2" role="status"></span>
            <button class="btn btn-primary w-100" type="submit" (click)="save()" [disabled]="editForm.form.invalid || isSaving">{{ "TOPBAR.CHANGE_PASSWORD" | translate }}</button>
          </div>

      </form>
    </div>
</div>
